const Songs = {
  salsa: [
    // Oren's songs from https://open.spotify.com/user/orenso/playlist/5ahnssobNZ1j6js1XtEcfa?si=yRKm0EyqQnqEOiZpqOY9xA
    '11i880AC0EIX8azPooV52r',
    '7ilVeP52B7yBCOz4fPQMVE',
    '5M830cD7MNeiiwIGHzH9TV',
    '1yM7GIEJ2QniupkEHOyp0a',
    '3dE0LmLCkCUyMa51GCwAOv',
    '3fucYASejkSMwtAYjFRKlF',
    '0n2SEXB2qoRQg171q7XqeW',
    '50ie858tDK1UM2hhB60A3W',
    '4iImxqMlF3CeNRwKKua9jn',
    '1Oex59PRSZtOqRHEPpf8h0',
    '5lQCYm9wTxbRgvrNNwN9xu',
    '3VtxQCEOnJ8Ge65CdQwvsa',
    '6e41VqRGWZGvCKtJ5roSEx',
    '5c03MWAT1BmzF8bcEssWgn',
    '1nZziTuPRwCfJe1XvVC760',
    '0KVP7acJXaxGx10hutXpui',
    '23Fw0qh3Onqn2276LiL2pT',
    '13zhZuedpA05BxrInLtfbo',
    '1IcnHHfeAkdc88LybFWwb9',
    '0vUctBW4so39hS6IZ7ibKw',
    '23Vb5teQKNSNvPgUjLtI8j',
    '14AzRctwIdAs8xTZkfcpko',
    '2GBTcQdwU4pEEreRvAQkqI',
    '0ukNB7NCEpEzQPg6uH9WFB',
    '3QHMxEOAGD51PDlbFPHLyJ',
    '4ckiIIk3NDOwxPeoMoVFcF',
    '0c6Ol8Fk2Cu4PzLGCbKcut',
    '0dqfl4l45vqw2TnNrnbsy0',
    '2fM9D3e5pyVBzqOBgMC8ap',
    '6l9iRzgakAc5U82eVbt8ow',

    // Songs from https://open.spotify.com/user/spotify/playlist/37i9dQZF1DX4qKWGR9z0LI?si=Cd6MkmfST1Opy7JJRzIHOA
    '5E7tWGNU9baL3BODJmApsV',
    '3c5PYEQmBkxqxsb0xMsRnk',
    '15LaR0cTTGwM9D6Q02ay2L',
    '5OYAkymF4NECxYhNIero4j',
    '7F6O4IJtrHNBdKFpcHwMJg',
    '60VVaKgIJNftR2UWkLIYah',
    '1MZGLqbtT6BQs3OM7R92UY',
    '0amRhMUb9wYrDOmSD938AQ',
    '23Vb5teQKNSNvPgUjLtI8j',
    '3bpqoOSDwdaK003DPMvDJQ',
    '4YGSpi9g7ohPFO8TXpvSUZ',
    '3SXWtn3YbXOuz6s9LN8LeJ',
    '1m3paVx65imhvCjPx505Oy',
    '00Ro1lnV3V6i87aY4t8Q43',
    '383hkMdmA976zOTKRm6PLy',
    '2soM5TyZ2c98CDMvXljT7a',
    '6QJ1F9DrlsRSmKDcdVS0Oy',
    '58unXNsISeHfI2jdqm9nb9',
    '3WXfQDsASfXrSojk9WB3aR',
    '6UGYIgkzeLp4BQy30B9svV',
    '0gVmUkRX09cY5uNPSI74JC',
    '4nYuL6R1SuWAOJRwAWasct',
    '4Ih0JljQ3X3PbHfmuk05PR',
    '6F3YJ3vWzatHA3nz6GwDoA',
    '2fhcGmlZZ0j3oAKTaiuk91',
    '1XuSve6t0vLCm8MG7CZJOA',
    '5y37hGmb0LU3YiZRlFJGUR',
    '05K2YWeCYYBgPWFj8TT4uK',
    '4OQrHFhoNSDxSFitx9KCbx',
    '0yp6jw6F0LNnjbxLejWaPK',
    '3yEtuhGR8jOtusa29jWrhG',
    '1N3G6QQfF0579fHoFQmMzi',
    '0C279YvVWJcmFMSDtqIGfq',
    '2kBgJXGbVA6WcA40IFA9KF',
    '6bum69IXoiGcZCb83ZtfGA',
    '5p3xpIxdICGLWvjI5FEPng',
    '5bkWgrKyNhMcriQP3CrHEA',
    '7nDIflSHQXzaa8zupxwv3U',
    '286NP8kXWzx2uHb5tr7TGv',
    '4pUmQqaZklcCOB3rFdWc7g',
    '2h6WIOkTPvHSAD7vR6lMSS',
    '3fRIuTih8bzc0vtY9fHhvF',
    '5YfqVqJwjxiyhxvofbCnHp',
    '39cz1Ua6M1YFAjxKxipgOK',
    '6sna5KCgNbt1ciZtVJCjzx',
    '2vHNqDJe1qvt1sroQfNHSI',
    '6Mo9TgSE7iKBNSoIdLaP40',
    '14XQClWbLwmDywY9wVaDiG',
    '5B0M2VaOrYGkQdGc044Mfe',
    '5uUtqceCsKoQ2J7DDGNbIE',
    '2WOMIZnu99mzeMo13bZq8m',
    '3QHMxEOAGD51PDlbFPHLyJ',
  ],
  bachata: [
    // Oren's songs from https://open.spotify.com/user/orenso/playlist/3RmBmFzS8OJrhs4LDOLow9?si=TJWS0IGSRq2_Xk6TgHxAUQ
    '2TGQVxPAdCObGhvWQXSLqs',
    '6eUncuJutsFi9BGO1JaBHh',
    '0DQd0tWurMHUAv0cMnDELH',
    '65H6t1WQBim6q93yM8fEwn',
    '0zL9dieoCcjBWV01y73Cj1',
    '2dy3TKVDEbIeWxeIb8JWqZ',
    '46HdsiK0uR26B2dY9QPzda',
    '1QVfRNDgmmqQQlAFXwJMar',
    '7jkIwEJXP3nmTO3fsCJmnS',
    '42juBvAuH9xTPhTXVaxK4W',
    '4YqSYw3j1jY5ihxBG06SoO',
    '3zLZzw2t5mbsLaNQURufkB',
    '1LitgN2tFmNVEtLY6qh5SZ',

    // Songs from https://open.spotify.com/user/spotify/playlist/37i9dQZF1DX7MTlMMRl0MD?si=FzvfufOOSnWKeupf17UnBw
    '6FwEmbPo3d3USlMzuulT69',
    '2swZ0X1njeU32SNJeBKIfW',
    '1dZe54pxTPI1XsISVTR6ns',
    '2dy3TKVDEbIeWxeIb8JWqZ',
    '19CAKZdXVNyZrfmF60RAj3',
    '0hOpiCYtwEYqenDr8ME31H',
    '7hLYOkbsngrNAcIXbXfsQu',
    '1iLv1ieT9BZ3qsti9yTCnG',
    '0M9Qr6K4wfoGUDBDgSzXgf',
    '0HDHY6RSHHG2ZTE0cMT4GJ',
    '4iTtqoImVortyy7MHw51Cv',
    '4SqiBk88oTJpVOzQYly9Zi',
    '2MioxSroS0g1mi24LLfcnq',
    '0muI8DpTEpLqqibPm3sKYf',
    '709hAukJxapqTU9VhsiQm0',
    '7a6htfBLgGUd4e36u2ISrx',
    '1WbXIpiGBJkCiv3hn40iPB',
    '6I86RF3odBlcuZA9Vfjzeq',
    '217bRMES4HVbPdUe8l8ECN',
    '6rXvMpWJbTyhSrVWye7jPE',
    '4Xqpt53kWML7eTObGxQ43i',
    '3fPMRb2gcFTZcYmR0bXnDc',
    '6BdAwMv1YorLfkBWlE493X',
    '3cZhoRYQFqJ8B9z0AN9gJB',
    '7sasRSZNmUPaHXLWndC8oj',
    '2kcc5humLS6PKOI9rwvXex',
    '3iGjLTDqokSuf6yYI83wzi',
    '31q1SLZZJZkdXENljyG1xC',
    '6CKlQHQIxhWtq7MnEJ6QCz',
    '0wDEs6WvqDHq4XJZC0dHhO',
    '0DQd0tWurMHUAv0cMnDELH',
    '65H6t1WQBim6q93yM8fEwn',
    '4dz2XW41AQTMn0bFKzaqsr',
    '03jN3MZ569uT3bzoh3pN5v',
    '4OpFR4sz9kxF983pKdnx1k',
    '0KBfGeUKLjNaernyUDUqgM',
    '1ePEbbeWx3scydfir7HBcq',
    '4AX0mPH9LnPtRPU7qYqTHW',
    '7oJ5gMtXIjVyx9plzoFkHU',
    '61LvtpZIualXhxhu8xkC2m',
    '1ehvUn5ei1Ef4XiK7Q32rs',
    '0vOU4wzq4KOLZia5bOMix0',
    '6wwRmRSc9OP9RdovSj7CZi',
    '6pMaJqcSPcB2JQNj23wJLN',
    '1w2cCY4iJ50Pm9qtwt310x',
    '4EzRYdHmOmUB7KXz1Dq8Ny',
    '7f7hBMVpdt2KGVOMHCGQnl',
    '3QeU2rcc5buv0B9VA0gRKH',
    '6mmza1GaPukLZ7tDYWcKj1',
    '61syCDsbd59CNRb9bEN32U',
    '6PqaUk1aS7KXHXBr8iELKb',
    '1dazXPzmgBB7sNxodglb1l',
    '5vY9L2lWnaCXwecxaUigps',
    '18hnXK4HWm3BxT8QijiJVO',
    '5ak2OdhjNeZrksibKWG19G',
    '42jedyDXAZydzL3OrslfUp',
    '1sRLHqXAohDI070tWU6QIS',
    '7CLP5aEc8SAYdhBqy2Zwjr',
    '4NfwBgmzmzx8WpyIy9C6p7',
    '1o5xQ4v5K9RqsU5xVbkDY6',
    '4pJDYk0CnZoydOy6qzqxJW',
    '1PL6AwnzEDWjKErmFgDgoT',
    '3vAPw05pWZ3uPI1SbqG1vv',
    '1dN6LzonVuFgQ90x4Ttrse',
    '0OgX5NA7KFx8FCr5W0HYl9',
    '1sjP5ywDibuwKax81gHCTG',
    '3P3KYALsULoYB2D8qIuu2A',
    '6HI59DNB97wXvOyP3kWdyE',
    '6IfMD01z39P15HKezjdaMP',
    '44ayrb3kbN6jwX9WWdoTcV',
  ],
  merengue: [
    // Songs from https://open.spotify.com/user/spotify/playlist/37i9dQZF1DWVAa4Dwc5f7L?si=T1vDIlH0TuiSonrvdNX4HA
    '2gT8OWcAgPg0yawg1qw4Nv',
    '05fjQ7QDmLNxhGZvWwqHvH',
    '3lUWGTKZuzP2NmGUwe1ox8',
    '7sYp72BQkl2HxgPX3eeQW0',
    '27bYvOSXnVkJEbycstEkR6',
    '1ZWHHugA5Zl47mhEuCdeoT',
    '0UnJphaDGpljP59lXRMaiE',
    '7IlywUbjL6TBFQyAzCqOsf',
    '0v1LHKfY09iflWGxTYL6qW',
    '5NDZ8iNl6Q8KvZthfzYLsZ',
    '0iZzWrpdwwFrynCW0bmyVe',
    '3mO4W4lB8wDrQ0UE3dz4gh',
    '3T4QCjtq7USCFlaOotam9Y',
    '5HaJFkq7DEQ6QMaj0wwI7Z',
    '1MVJppKg8rGNtDuy4YKWME',
    '2ltjgZUx2DgxdYGxuyhNAd',
    '01PPF7e3AD0LE3V9DTDURP',
    '7reg3cRS3uPi326LD1F3e5',
    '0WzWqQlZBzxgzVhm8Ep4CX',
    '3Dxxf8mFAQg8MRcsCZKOKm',
    '2pGYaMlxkicWR0Pkddlmi8',
    '00oK3qICo8tnl1WN3ah5aD',
    '5bpQBpeGQ0vRYs5JTGqR1t',
    '13PqqV8pIb6XpdZ85CACxF',
    '6ZOZW00i9w8wwfwZtpreZI',
    '4UHi2puJF8VEdpC4AJfKku',
    '0zDy3gddxKzHvOqbfryNg9',
    '3srURD7HBktaD14VKzctR4',
    '4u6DLFZrXINev3s4IfDobf',
    '2zPWfNLVf6rObUn27LKlLy',
    '66Zw21Bjq8jppl6BrOv1JZ',
    '6Aqtgsk7nzqAXird9otVEe',
    '2qlsW9JoDmXNyM7odbFxmn',
    '6hFhxzvpcWmtDJre0ejXEj',
    '6WVdJyM1kF6SGrLERsiM1a',
    '5w8uBAoUDhjGab0EACmmaO',
    '47nD9EtDtUORYiLPDY4DeI',
    '7Losbk8sx6RuMCq4Rp1E8M',
    '6BN6upiyMThT7WtgdQZzZB',
    '3htW78fd7ocb9rrgnXOLjk',
    '6qf6WInODgkkK4503iSea5',
    '6uDRMte0V2h22GZ24TLafo',
    '4T8nmzARtOIVPuYJ0fvvMX',
    '5fAlFBYi2rLMdvaRF5PFs0',
    '392fx2nlTxr8VrMUU9ybqi',
    '3fO9MbNQQGMtVzyLGwtxRd',
    '0HXa7KKhJdwU5ypGHvwzBw',
    '1xu6ahswvBgOe8V7DFHhPC',
    '1oInQX1RuH1TcHuDv8Gvw3',
    '5s9qThhS2o3TommmmcmoqU',
  ],
  kizomba: [

    // Songs from https://open.spotify.com/user/carloncho-30/playlist/3o9uOgxyrM8vRI53yfkNxY?si=kAc-En-RSju5SGVILTs_jQ
    '7k6GtIlNKrWcBPadpWGIau',
    '0fbuZJyu6gyHJXruk0vGkz',
    '3bsocdNop8BRU7xUe91ZO5',
    '79pSYGwwdS59svr4Zj8bkO',
    '0M0S7wJ8qtvHssRkytuRjt',
    '1zmgXgjQBm9cWcNN5f0cwf',
    '7x2U8a8ltt9hLBZINnbb5d',
    '1HmpJAEanU06DdSwxNwFO8',
    '7dCbLzLWVcYdOKmbk6X5Wj',
    '3M9XQDFTVjSigY65uG2noK',
    '1jAcTNZgAVIG5R8epBytaW',
    '3t2axM2WR6Tyy5lQ60H3DP',
    '37LhMIzhMkMVVjwJjuKXh0',
    '3f68uxYfcFipceVEqyqoQo',
    '0fFYQpXcySoP1aTAesmNTO',
    '0jmwFUYbHEqPY60yeJIGvT',
    '1WPWKPJTITG97hvpBFtmpL',
    '709GVlO1GNZQPOxLXQi3PO',

    // Songs from https://open.spotify.com/user/salsero85il/playlist/5Y9VfrCFt0NgEmyjTVseWr?si=Sf1I0G2XQvmgUDoyoGkWKQ
    '6PhXswbZFwFlGWiyCuNx1y',
    '7t1VAARhqmYg30l5OgnUcw',
    '1Xx1zfcvWMgin1mA8ZPyjv',
    '00lfmKuo5NCp0LC61FLjPU',
    '2K3hYYDX6ueaPy403PJqi2',
    '0qN0K23Us6ozC5CL6q0uAL',
    '6pRla6fggbVFdsp5jUTuJx',
    '1AuCWhqJ4vPic3b59zjUpI',
    '7rDJZ9VmZEhvi1sgePKFKy',
    '1CyFiX0xKRBNm8kGmfSFLt',
    '5gN25z7YcmTSEOF65f9K23',
    '3Rl787jeOJZ0qcR05PBSks',
    '1WPWKPJTITG97hvpBFtmpL',
    '6VTzUSp0U0S1Jw30p2yn4Z',
    '3uRgviLV9EsljL5wVcB24w',

    // Songs from https://open.spotify.com/user/spotify/playlist/37i9dQZF1DX1l6qs3gcM4U?si=XoJN97acQVeBE4ot5ChbQw
    '0yK1An55fUL6ZgYzWqb3UX',
    '1xb4uv25MokAY9xos31WXL',
    '3y8Un4QcNTLwAIIgOroXzf',
    '6n2wC4XVCaSVpJMxBPrJMN',
    '6VmyaG1l5VG69AGLecdqpq',
    '2Rz27kKIB8LlbjzvyM1utV',
    '0pnQuPugcSa1anG10jlxkz',
    '2CV9x25ByU2nmmeHSSn4NX',
    '78guu2HTRnNgncK0UySeDK',
    '5ZhYcNNdJMYUkUkFjeuWDI',
    '3hVuyS22fIMAEvyDx2Nxih',
    '6bqn1qdCyqWAjS8sAaqzFk',
    '5nXSUlaOCd4sFVHMyjpaDB',
    '3AxKlhbCVMwrDbP4laSyys',
    '5NOwexYooxAsUxHiVXv3Rh',
    '5u2FrLHKEoKjUMguaffQ0t',
    '6kNQMH8SSCbwSWRbdYvg4U',
    '6HkqDpMmTV2IT2I01a9Zp5',
    '2j1UBahUo1bbMu6rHC5GnE',
    '7zEFn5PC91OB6KYFjCxMHi',
    '2Huk69nfKmAqeN4m2S7Ybj',
    '0ZWU5lJs0mA085Phf8PJA2',
    '03hnjC8kAVy9tmfgcB0p0y',
    '720YR0UhybUYFQUU4ZAWwP',
    '2pSOoP6FLJWeRBxdvhyvkJ',
    '20pd0IMbN7KC9y0FNOVQK0',
    '7qeh3o6nW5XCZZjbMCEVWd',
    '0r2ZBAHKX36U3do2a3E0Vd',
    '6qhwWPfW7AqhWoJPEjyylb',
    '7IkiNEEUdZboslsmcFuhtJ',
    '0oZZUl9SpZ9qQvn6HtxXOR',
    '49Fu8EcEOZ9OnpM1XpPpyU',
    '1d8RXKUyIQkgrZel7CvFV0',
    '3T2ag3TOeD3IDqVVfl9ilU',
    '4rbmWqxIAQpr5CPVfJ1O7J',
    '101bSnuKjwls52XChDbfvg',
    '6eD6PhPI0r20NQkbtg8Ywb',
    '2ieA58j9Fsuo35Gwa6oX8P',
    '0RKqylCDwg0Lzq8Stu266U',
    '4nPEwC4QmSEJFI3CJL9e4b',
    '1iRDn08Im0yWFH1b7oZ6E5',
    '24wV7K8ryMHWDoseisRafk',
    '6EMvAQTdJ0Xs7XV5D5dOun',
    '1F1EJj1aVtbeI0y0hcFtRh',
    '2axmBwJacXBbSqvVbxtNzY',
    '46Kt8G76lbRNkGt4zxCFrp',
    '5cw7KhORjRNFEP5Yyfs98O',
    '5hIY2jJsMtrMQxW9ivn809',
    '0WKVGkGPq8NtuLSSQUUu8X',
    '235N5XR969xiEa5LvjKheT',
    '5gM4UYTTTcVyg7KsPAbyGR',
    '3JtkjYRgX5kY2aK5WwYjSk',
    '4tb70HZ8PwboF7aGGVBl1I',
    '1AuCWhqJ4vPic3b59zjUpI',
    '4vGrg4XZcLaGvhRpAPKdnc',
    '3LV7JsBzte182GK1p6wOUX',
    '4MWpwncU5J32siqYoMJXPp',
    '25JonxVTCZOIul9uyv3ff6',
    '30GRqeKxTuZQ1JEeCvHJKY',
    '77fXpodJPRKqvnzDiMYkAj',
    '0WP4sAf9JKYvy1neTliv5C',
    '7JoecvZbqyl6yM9j0cUgxr',
    '1Rqauh63hRo1A201F6lvH7',
    '7qWszdYQHJUXLmlmDgWyqp',
    '4SDnHKQfewFoUA61Q9rojm',
    '5PhrhizCs4VCuFjJPvhI5v',
    '5koelUYXOtUwFvRvuaNu2n',
    '3ooG7GI5gSSROggAE2pnji',
    '08W33tq3AxN2RtbQGNxrBa',
    '6BLLoHOQqY3rOpMoaO6yHv',
    '0YOLLYNnFbOz0xagmru0SD',
    '3HjUVOsrtf2fkJRXaXzhkO',
    '2eWly1qDvIMnrV91WGvAzX',
    '2qNtAzgJYODcFPDskUAzE0',
    '4dOY2bDFu1AWr87MUd09Zh',
  ],
  reggaeton: [
    // Songs from https://open.spotify.com/user/spotify/playlist/37i9dQZF1DWY7IeIP1cdjF?si=HIiBBetPRVallXrhVS-7Kw
    '6RyaV7owmVU6fzEPE17sF1',
    '6mAN61JH0dzyZpWslS11jy',
    '4AjWbAwRpxk2worpjNH5CS',
    '5099x34vBakWpGkHourFxP',
    '2ksOAxtIxY8yElEWw8RhgK',
    '59s0s39NFWScuHDbHytI14',
    '3jbT1Y5MoPwEIpZndDDwVq',
    '7rknFhrVDyxzTJkPSf7LoW',
    '2TH65lNHgvLxCKXM3apjxI',
    '6pNjmMB8FTMEcqGSgs7Mji',
    '1tpbAGsGpZee5jDHMcLrUJ',
    '3KekO5WUfJsh7kYU9o2w50',
    '0fea68AdmYNygeTGI4RC18',
    '6Y4PDQv4XjYjHLeLmvyOt0',
    '65HT0L2eAtvbH56XrJqPbf',
    '1ndyl3wJCFs872XZ3ztPk6',
    '1ScD7XQYCKmJ3fdNh71lUE',
    '684EjRHwNsZQ9hCQxL4NYL',
    '4o1DxRjMCHDwBiOKv88rOl',
    '5CQzOnFWI0liUblbHYVNge',
    '1c0hsvHLELX6y8qymnpLKL',
    '2EEmzqzM70CybVspgM7YRT',
    '2kjdTPSRPS9mVfRlaenjsf',
    '2B94bbI1a4LyBpK1439oP7',
    '6Y6YC93R1r0CISm5iC4j27',
    '2DFn7QiaiaNNWXuWLABatP',
    '3J1zbyaI1m4t8oRGgF56oi',
    '3dWpRjCmaHbroNahuSeX11',
    '1GBLO0NFODuL31A5fgw0uR',
    '1KdwnsWOvhM53vezy5ROuf',
    '1sD32hk8V2vR4ME7M0rPT5',
    '6K5BsR04ijf3FHNzjbaagD',
    '0xkUr72eFk4GjSRoEHOyfD',
    '53uKGbcMjEc2gIWbfmvpdS',
    '1aUGxXCz9QimNkKeiJtzZ7',
    '25ZAibhr3bdlMCLmubZDVt',
    '3TTragde5roTvwS2caAQOc',
    '6ycsFSUUgyOSZiz3P2o1pT',
    '4NLcgBskZkUYgpAVQF0sNI',
    '5lFl0Aksww4t4CGiYYtpZz',
    '7pxL0DzPpCjjcdfQccvnus',
    '5CGFApNEqgMcoKo6Q4KJ3g',
    '7DOh0tBGnyMt6C5OO7o8Yq',
    '7fTsw0IKbbPDN19keS32tF',
    '04x4Urpge2kG9uDGdHRsdc',
    '5suqjbw9bF0T1X3HU1oG1v',
    '0a41S6Hf3njfUbQTZyxWDq',
    '5uVgsAzjO6eJR06VPrqUga',
    '67OKX8DNYygddabwIjExsp',
    '54FoR5osiOJtVae7SCdSnq',
  ],
};

export default Songs;
