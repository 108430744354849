/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Main from './components/Main';
import './scss/App.scss';

function App() {
  return (
    <div>
      <Main />
    </div>
  );
}

export default App;
